import React from "react";
export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-300 pt-8 pb-6">
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
                Copyright © {new Date().getFullYear()} by{" "}
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/PortugalHomeTraveller/about/?ref=page_internal" className="text-gray-600 hover:text-gray-900">PHT Team</a>.
              </div>
            </div>
          </div>
      </footer>
    </>
  )
}
