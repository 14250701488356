/*eslint-disable*/
import React, {useState, useEffect, useRef} from "react";
import { Link, useHistory } from "react-router-dom"
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import AccomodationsImg from '../assets/img/categories/60B2EE.svg'
import RestaurantsImg from '../assets/img/categories/EE6060.svg'
import ActivitiesImg from '../assets/img/categories/B447DC.svg'
import PointsImg from '../assets/img/categories/EEB560.svg'
import EventsImg from '../assets/img/categories/5FEDE4.svg'
import AmenetiesImg from '../assets/img/categories/E83E8C.svg'

import ComponentsMapImg from '../assets/img/components/component-map-card.png'
import ComponentsSearchImg from '../assets/img/components/component-google-maps-card.png'
import ComponentsCategory from '../assets/img/components/component-categories-card.png'
import ComponentsInterests from '../assets/img/components/component-categories-interests.jpg'
import ComponentsFriends from '../assets/img/components/component-friend-card.png'

export default function Index() {
  const nameRef = useRef('')
  const emailRef = useRef('')
  const history = useHistory()
  const [checked, setChecked] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if(checked === true) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [checked])
  
  const handleClick = () => setChecked(!checked)

  const handleSubmit = () => {
    const form = document.getElementById('sheetdb-form');
   
    form.addEventListener("submit", e => {
      e.preventDefault();
        console.log(nameRef.current.value)
        console.log(emailRef.current.value)
        if (nameRef.current.value !== '' && emailRef.current.value !== '') {
          fetch(form.action, {
            method : "POST",
            body: new FormData(document.getElementById("sheetdb-form")),
          }).then(
            response => response.json()
          ).then((html) => {
            console.log('submit')
            history.push("/submit/")
          })
        } else {
          alert('Please fill the Form!')
          refreshPage()
        }
      })
  }

  return (
    <>
      {/* Header */}
      <IndexNavbar fixed />
      {/* Hero */}
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-3xl text-gray-700">Portugal Home Traveller</h2>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">Portugal Home Traveller, PHT, it’s a community for people that like to travel and experience life and share it with others! For people that like to connect and are looking for experiences and enjoy different cultures.</p>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">We want to give you one place with quality and reliable recommendations for travelling or start living in a place, and connect with local people and friends, so you get a more worthy and special experience.</p>
              

              <div className="mt-12">
                <a href="#join" className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                  <i className="fas fa-rocket"></i>
                  <span className="mb-image-m">Join Us Now</span>
                </a>
                <a style={{marginLeft: '12px'}} href="https://play.google.com/store/apps/details?id=com.pht.portugalhometraveller" title="Comming Soon" className="text-white font-bold px-6 py-4 rounded outline-none focus:outline-none bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150" target="_blank">
                  <i className="fab fa-google-play"></i>
                  <span className="mb-image-m">Play Store</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <img className="custom-image absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px" src={require("assets/img/pattern_react.png")} alt="..." />
      </section>
      {/* 3 Sections */}
      <section className="mt-48 md:mt-40 pb-40 relative bg-gray-200">
        
        <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
          <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0"><polygon className="text-gray-200 fill-current" points="2560 0 2560 100 0 100" ></polygon></svg>
        </div>


        <div className="container mx-auto pb-20">

          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto -mt-32">
              <div className="justify-center flex flex-wrap relative">
                <div className="my-4 w-full lg:w-6/12 px-4">
                 
                    <div className="shadow-lg rounded-lg text-center p-8" style={{backgroundColor: '#60B2EE'}}>
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={AccomodationsImg}/>
                      <p className="text-lg text-white mt-4 font-semibold">Accomodations</p>
                    </div>
                    <div className="bg-blue-500 shadow-lg rounded-lg text-center p-8 mt-8" style={{backgroundColor: '#EE6060'}}>
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={RestaurantsImg}/>
                      <p className="text-lg text-white mt-4 font-semibold">Restaurants &#38; Bars</p>
                    </div>
                    <div className="bg-gray-800 shadow-lg rounded-lg text-center p-8 mt-8" style={{backgroundColor: '#B447DC'}}>
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={ActivitiesImg}/>
                      <p className="text-lg text-white mt-4 font-semibold">Activities &#38; Expirences
                      </p>
                    </div>
                </div>

                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8" style={{backgroundColor: '#EEB560'}}>
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={PointsImg}/>
                      <p className="text-lg text-white mt-4 font-semibold">Points of Interest</p>
                    </div>
                    <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8" style={{backgroundColor: '#5FEDE4'}}>
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={EventsImg}/>
                      <p className="text-lg text-white mt-4 font-semibold">Events</p>
                    </div>
                    <div className="bg-green-500 shadow-lg rounded-lg text-center p-8 mt-8" style={{backgroundColor: '#E83E8C'}}>
                      <img alt="..." className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src={AmenetiesImg}/>
                      <p className="text-lg text-white mt-4 font-semibold">Amenities &#38; Facilities</p>
                    </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-12 lg:mt-24">
              <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-list text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">App Categories</h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">We want to give you quality and reliable recommendations for travelling or to start living in a place, such as Accommodations, Restaurants, Bars, Activities, Events, Experiences and more. With everything in one place, based on your preferences and past experiences. You have filtered search to adapt to your lifestyle preferences.</p>
            </div>
          </div>
          
            <div className="flex flex-wrap items-center">

              <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto pt-12">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-mobile text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">App Components</h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">PHT has a list of Categories, a list of Friends, and an interface with a map and its recommendations. On the map, we can browse the points of interest and identify them by their color, and on the listings, we can also see our approved recommendations.</p>
              </div>

              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                <div className="relative flex flex-col min-w-0 w-full mb-6 lg:mt-48 md:mt-0">
                  <img alt="..." src={ComponentsInterests} className="mb-image w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px" />
                  <img alt="..." src={ComponentsMapImg} className="mb-image w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px" />
                  <img alt="..." src={ComponentsCategory} className="mb-image w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2" />
                  <img alt="..." src={ComponentsFriends} className="mb-image w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px" />
                  {/* <img alt="..." src={ComponentsFriends} className="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px" /> */}
                  <img alt="..." src={ComponentsSearchImg} className="mb-image w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px" />
                </div>
              </div>
            
            </div>

        </div>
 


        <section className="md:pt-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-12">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">Type of Users</h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  The types of user will grow, join us and see!
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img alt="..." src={require("assets/img/team-1-800x800.jpeg")} className="shadow-lg rounded-full mx-auto max-w-120-px"/>
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">User</h5>
                    <p className="mt-1 text-sm text-gray-500 font-semibold">Travel and use our recommendations, you receive <br></br>travelling benefits by using our app</p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img alt="..." src={require("assets/img/team-2-800x800.jpeg")} className="shadow-lg rounded-full mx-auto max-w-120-px"/>
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Friends</h5>
                    <p className="mt-1 text-sm text-gray-500 font-semibold">Locals and experts, that make suggestions, you can choose which ones<br></br> are close to you, in terms of interests or location</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <div className="container mx-auto">
          <div className="flex flex-wrap items-center mt-8 pt-32">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                <img alt="..." src="https://trello-attachments.s3.amazonaws.com/5f1da258d3f667727aa2a0a0/5ffb2bf6318c290a8e7847a1/c257e6f72b95e1007a03ce692adafd60/pht-start.png" className="w-full align-middle rounded-t-lg" />
                <blockquote className="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" className="absolute left-0 w-full block h-95-px -top-94-px" ><polygon points="-30,95 583,95 583,65" className="text-blue-600 fill-current" > </polygon></svg>
                  <h4 className="text-xl font-bold text-white">Beta Version</h4>
                  <p className="text-md font-light mt-2 text-white">Portugal Home Travellers Aplication makes it easy to invite users to test our app and collect valuable feedback before releasing the oficial version. </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div style={{paddingLeft: "30px"}} className="flex flex-wrap">
                <h3 className="text-3xl mb-2 font-semibold leading-normal">Coming Soon</h3>
                
              </div>
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fab fa-android"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Android</h6>
                      <p className="mb-4 text-gray-600">Build in Native Android Language</p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fab fa-google-play"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Play Store</h6>
                      <p className="mb-4 text-gray-600">You can Download our App no Google Play Store</p>
                    </div>
                  </div>
                </div>
                
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-fire"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">FireBase</h6>
                      <p className="mb-4 text-gray-600">Hosting and Backend Logic on Google Fierbase platform</p>
                    </div>
                  </div>

                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fab fa-bitbucket"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">BitBucket</h6>
                      <p className="mb-4 text-gray-600">
                        Git version control featured on BitBucket platform
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </section>
      <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 pt-12 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Join Now
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  And you’ll be the first to know when we<br></br> release features and new content.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center"></div>
          </div>
        </section>
      {/* Form */}
      <section id="join" className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10">

                    <h4 className="text-2xl font-semibold">Want to join us?</h4>
                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">Find out more and join our community!</p>
                    <form action="https://sheetdb.io/api/v1/j7kytn32pemul" method="POST" id="sheetdb-form">
                      <div className="relative w-full mb-3 mt-8">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="full-name">Name</label>
                        <input ref={nameRef} name="data[name]" type="text" className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Name"/>
                      </div>

                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="email">Email</label>
                        <input ref={emailRef} name="data[email]" type="email" className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Email"/>
                      </div>
                      <input name="data[date]" type="text" className="hidden" readOnly={true} value={new Date().toLocaleString()}/>
                      
                      <div className="flex flex-wrap">
                        <label className="inline-flex items-center cursor-pointer">
                          <input onClick={handleClick} checked={checked} readOnly={true} type="checkbox" className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150" />
                        </label>
                        <span className="ml-2 mr-2 text-sm font-semibold text-gray-700 hover:text-gray-900">I agree to the</span>
                        <a href="https://pht-terms-and-conditions.netlify.app/" target="_blank" rel="noopener noreferrer" className="mr-2 text-sm font-semibold text-gray-700 hover:text-gray-900"> Terms and Conditions </a>
                        <span className="mr-2 text-sm font-semibold text-gray-700 hover:text-gray-900">and to the </span>
                        <a href="https://pht-privacy-policy.netlify.app/" target="_blank" rel="noopener noreferrer" className="mr-2 text-sm font-semibold text-gray-700 hover:text-gray-900"> Privacy Policy</a>
                      </div>
                      
                      <div className="text-center mt-6">
                        <button title={disabledButton ? 'Please agree to the Terms and Conditions and the Privacy Policy' : '' } disabled={disabledButton} onClick={handleSubmit} className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="submit">Send</button>
                      </div>
                  
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* Footer */}
      <Footer />
    </>
  )
}
