import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/styles/tailwind.css"
import Index from "views/Index.js"
import Submit from "views/Submit"
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/submit/" component={Submit} />
      <Route path="/" exact component={Index} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
)