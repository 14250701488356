import React, {useState} from "react"
export default function Navbar(props) {
  const [navbarOpen] = useState(true)
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a href="https://www.portugalhometraveller.com/" target="_blank" rel="noopener noreferrer">
              <svg width="110" height="38" viewBox="0 0 1075 362" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 228C0 161.726 53.7258 108 120 108H134C200.274 108 254 161.726 254 228V242C254 308.274 200.274 362 134 362H0V228Z" fill="#2D374C"/><circle cx="127.545" cy="234.455" r="68.9399" fill="#2D374C" stroke="#4299E1" stroke-width="30"/><path d="M504.589 129.95C519.345 144.468 526.723 165.888 526.723 194.21C526.723 222.532 519.345 244.071 504.589 258.827C490.071 273.345 469.484 280.604 442.828 280.604H393.919V362H344.296V107.816H442.828C469.484 107.816 490.071 115.194 504.589 129.95ZM477.1 194.21C477.1 166.126 464.843 152.084 440.329 152.084H393.919V235.979H440.329C452.229 235.979 461.273 232.766 467.461 226.34C473.887 219.914 477.1 209.204 477.1 194.21ZM756.963 362H700.2V240.62H620.232V362H563.826V115.67H620.232V223.484H700.2V115.67H756.963V362ZM1029.23 183.5H974.256V177.074C974.256 163.27 971.162 152.798 964.974 145.658C959.024 138.28 951.051 132.449 941.055 128.165L937.485 127.808V362H880.722V127.808H877.152C854.78 136.614 843.594 153.155 843.594 177.431V183.5H788.973V177.431C788.973 164.817 792.424 154.107 799.326 145.301C806.228 136.257 815.629 129.474 827.529 124.952C839.667 120.192 852.281 116.86 865.371 114.956C878.699 113.052 893.217 112.1 908.925 112.1C924.633 112.1 939.032 113.052 952.122 114.956C965.45 116.86 978.183 120.192 990.321 124.952C1002.46 129.474 1011.98 136.257 1018.88 145.301C1025.78 154.107 1029.23 164.817 1029.23 177.431V183.5Z" fill="#2D374C"/></svg>
            </a>
          </div>
          <div className={"lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +(navbarOpen ? " block" : " hidden")}id="example-navbar-warning">
            <ul className="flex lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a href="https://www.facebook.com/PortugalHomeTraveller/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  <i className="text-gray-500 fab fa-facebook text-lg leading-lg " />
                </a>
              </li>
              <li className="flex items-center">
                <a href="https://www.instagram.com/portugalhometraveller/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" >
                  <i className="text-gray-500 fab fa-instagram text-lg leading-lg " />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
