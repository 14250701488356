import React from 'react'
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Link } from "react-router-dom"
export default function Submit() {
    return (
        <div>
            <IndexNavbar fixed />
            <section className="h-screen pb-40 relative block bg-gray-900">
                <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"style={{ transform: "translateZ(0)" }}>
                    <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" ><polygon className="text-gray-900 fill-current" points="2560 0 2560 100 0 100"></polygon></svg>
                </div>
                <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
                    <div className="flex flex-wrap text-center justify-center">
                    <div className="w-full lg:w-6/12 px-4">
                        <h2 className="text-4xl font-semibold text-white">
                        Thanks For Join!
                        </h2>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                        Thank you for joining us! Looking forward to have you on our community. Let's travel and enjoy!
                        </p>
                    </div>
                    </div>
                    <div className="flex flex-wrap mt-12 justify-center"></div>
                </div>
                <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                        <div className="flex-auto p-5 lg:p-10">

                            <h4 className="text-2xl font-semibold">Want to keep Reading?</h4>
                            <p className="leading-relaxed mt-1 mb-4 text-gray-600">back to 
                                <Link to="/" style={{textDecoration: 'underline'}} className="underline hover:text-gray-900 px-2">home page</Link>
                            </p>
                        
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
        </div>
    )
}
